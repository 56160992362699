<template>
  <div>
    <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="getExcel()" text><v-icon class="mr-2">far fa-file-excel</v-icon> Exportar</v-btn>
  </div>
  </template>
  
  <script>
  import notifications from '@/utils/notifications'
  import {excelActivities} from '@/api/salesNotes'
  import moment from 'moment'
  import numeral from 'numeral'
  export default {
    name:"ExportActivities",
    props:['filters','options','disabled','search'],
    data() {
      return {
        loading: false,
      }
    },
    methods:{
      async getExcel(detail){
        this.loading=true
        const {sortBy, sortDesc, page, itemsPerPage }=this.options
        let sort = sortBy[0]

        //Formato a la fecha para el query
        var created_before = ''
        var created_after = '' 
        if(this.filters.created_after) created_after = moment(this.filters.created_after).format('yyyy-MM-DD')
        if(this.filters.created_before) created_before=moment(this.filters.created_before).format('yyyy-MM-DD')
        this.filters.seller=this.filters.seller?this.filters.seller:''
        const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&date_after=${created_after}&date_before=${created_before}&tipo=${this.filters.tipo}&seller=${this.filters.seller}`
        await excelActivities(query)
        .then((response) => {
          const link = document.createElement('a');
          link.href=window.URL.createObjectURL(response);
          link.setAttribute('download', `export_activities_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loading=false
        })
        .catch((error) => {
          if(error.code=="ECONNABORTED") notifications.showError('Error Exportando','Tiempo de Espera Excedido')
          else notifications.showError('Error', 'Exportando')
          this.loading=false
        });
      }
    }
  
  }
  </script>